















































import Vue from 'vue'
import { PortalTarget } from 'portal-vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import functionalUrls from './functional-urls'
import { layoutHead as head } from '~/services/seo'
import { Look as FooterLook } from '~/styleguide/patterns/Footer.vue'
import { isValidLocale, slugToIso } from '~/services/i18n'
import {
  zendeskOpen,
  zendeskClose,
  zendeskSnippet,
  zendeskSetLocale,
} from '~/services/zendesk'
import appConfig from '~/app.config'
import { getGeolocationRegion } from '~/services/user-locale'
import {
  trustedShopsClose,
  trustedShopsInit,
  trustedShopsVisibility,
} from '~/services/trusted-shops'
import vuex from "~/plugins/vuex";

export const selectNavbarTheme = (theme: 'light' | 'dark'): void => {
  Vue.nextTick(() =>
    window.dispatchEvent(
      new CustomEvent('selectNavbarTheme', { detail: { theme } })
    )
  )
}

export const selectNavbarPosition = (position: 'fixed' | 'absolute'): void => {
  Vue.nextTick(() =>
    window.dispatchEvent(
      new CustomEvent('selectNavbarPosition', { detail: { position } })
    )
  )
}

export const selectFooterLook = (look: FooterLook): void => {
  Vue.nextTick(() =>
    window.dispatchEvent(
      new CustomEvent('selectFooterLook', { detail: { look } })
    )
  )
}

export default Vue.extend({
  name: 'LayoutsDefault',
  components: {
    PortalTarget,
  },
  async middleware(ctx) {
  const { $vuex, route, redirect } = ctx;

  // Überprüfen auf fehlendes abschließendes `/` und Umleitung
  if (route.path !== '/' && !route.path.endsWith('/')) {
  const pathWithSlash = `${route.path}/`;
  const fullPath = { path: pathWithSlash, query: route.query };
  return redirect(301, fullPath); // Sofortige Umleitung mit Query-Parameter
}

// Prüfe, ob die Route in `functionalUrls` existiert und führe die zugehörige Funktion aus
if (Object.keys(functionalUrls).includes(route.path)) {
  await functionalUrls[route.path](ctx);
} else {
  // Lokalisierung basierend auf dem ersten URL-Segment
  const routeSegments = route.path.split('/').filter(segment => segment);
  const routeLocale = routeSegments.length > 0 ? slugToIso(routeSegments[0]) : null;
  const validLocale = routeLocale && isValidLocale(routeLocale) ? routeLocale : appConfig.defaultLocale;

  // Setze die erkannte oder Standard-Locale im Store
  $vuex.commit('INIT_LOCALE_SSR', validLocale);
}
},
  data() {
    return {
      isMounted: false,
      navbarTheme: 'light' as 'light' | 'dark',
      navbarPosition: 'fixed' as 'fixed' | 'absolute',
      footerLook: 'dark-neutral' as FooterLook,
      isRegionSwitcherOpen: false,
      geolocationRegion: '',
    }
  },
  head,
  computed: {
    isConfigurator(): boolean {
      return (
        !!this.$route.name?.startsWith('configurator') &&
        !this.$route.name?.startsWith('configurator-equipment')
      )
    },
    isAuth(): boolean {
      return !!this.$route.name?.startsWith('auth')
    },
    isOrderConfirmation(): boolean {
      return !!this.$route.name?.startsWith('order-confirmation')
    },
    isVideoPlattform(): boolean {
      return !!this.$route.name?.startsWith('video')
    },
    isVideoFind(): boolean {
      return !!this.$route.name?.startsWith('video-find-')
    },
    showSupportButton(): boolean {
      return (
        this.isMounted &&
        !this.isConfigurator &&
        !this.isVideoPlattform &&
        this.$zendesk.isAvailable &&
        appConfig.zendeskLocales.includes(slugToIso(this.$i18n.locale))
      )
    },
    showNavbar(): boolean {
      return (
        !this.isConfigurator &&
        !this.isAuth &&
        !this.isOrderConfirmation &&
        !this.isVideoPlattform
      )
    },
    showFooter(): boolean {
      return (
        !this.isConfigurator &&
        !this.isAuth &&
        !this.isOrderConfirmation &&
        !this.isVideoPlattform
      )
    },
  },
  watch: {
    showSupportButton(newVal) {
      if (newVal === false && this.$zendesk.isAvailable) zendeskClose()
    },
    '$i18n.locale': function () {
      trustedShopsVisibility(this.$vuex.getters.language, this.$route.name)
    },
    '$vuex.getters.region': {
      handler(newRegion, oldRegion) {
        if (newRegion !== oldRegion) {
          // @ts-ignore: Ignore the TS error since $loadTracify is injected at runtime
          this.$loadTracifyScripts(newRegion)
        }
      },
      immediate: true,
    },
    '$vuex.getters.language': {
      handler(newLanguage, oldLanguage) {
        if (process.client) {
          if (newLanguage !== oldLanguage) {
            zendeskSetLocale(newLanguage)
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.$route.name?.startsWith('cart')) {
      this.$vuex.dispatch('cart/get')
    }
    this.$vuex.dispatch('auth/load')

    // Check for coupon parameter on any page
    this.checkCouponAccess()

    window.addEventListener('CookiebotOnDialogDisplay', this.onCookiebotOpen)
    window.addEventListener('CookiebotOnAccept', this.onCookiebotClose)
    window.addEventListener('CookiebotOnDecline', this.onCookiebotClose)

    window.addEventListener('selectNavbarTheme', this.selectNavbarTheme)
    window.addEventListener('selectNavbarPosition', this.selectNavbarPosition)
    window.addEventListener('selectFooterLook', this.selectFooterLook)

    const userRegion = await getGeolocationRegion(this)
    if (
      this.$route.path !== '/' &&
      !this.isVideoPlattform &&
      userRegion &&
      userRegion !== this.$vuex.getters.region
    ) {
      this.geolocationRegion = userRegion
      this.isRegionSwitcherOpen = true
    }
    window.dispatchEvent(new CustomEvent('mountLayout'))
    if (window.isCookiebotOpen) this.onCookiebotOpen()
    zendeskSnippet(this.$vuex.getters.region)

    this.trustedShopsInit(this.$vuex.getters.region)

    // Trusted shops initalized
    window.addEventListener('trustedShopsLoaded', () => {
      trustedShopsVisibility(this.$vuex.getters.language, this.$route.name)

      this.$watch('$route', () => {
        trustedShopsVisibility(this.$vuex.getters.language, this.$route.name)
      })
    })

    if (this.isVideoPlattform || this.isVideoFind) {
      trustedShopsClose()
    }
    this.isMounted = true
  },
  beforeDestroy() {
    window.removeEventListener('CookiebotOnDialogDisplay', this.onCookiebotOpen)
    window.removeEventListener('CookiebotOnAccept', this.onCookiebotClose)
    window.removeEventListener('CookiebotOnDecline', this.onCookiebotClose)
    window.removeEventListener('selectNavbarTheme', this.selectNavbarTheme)
    window.removeEventListener(
      'selectNavbarPosition',
      this.selectNavbarPosition
    )
    window.removeEventListener('selectFooterLook', this.selectFooterLook)
  },
  methods: {
    trustedShopsInit,
    onCookiebotOpen() {
      const banner = document.getElementById('CookieBanner')
      if (banner)
        disableBodyScroll(banner, {
          allowTouchMove: () => true,
        })
    },
    onCookiebotClose() {
      const banner = document.getElementById('CookieBanner')
      if (banner) enableBodyScroll(banner)
    },
    selectNavbarTheme(e: Event) {
      const event = e as CustomEvent
      this.navbarTheme = event.detail.theme
    },
    selectNavbarPosition(e: Event) {
      const event = e as CustomEvent
      this.navbarPosition = event.detail.position
    },
    selectFooterLook(e: Event) {
      const event = e as CustomEvent
      this.footerLook = event.detail.look
    },
    checkCouponAccess() {
      // Process the coupon query parameter on any page
      const coupon = this.$route.query.coupon
      const trial = this.$route.query.trial

      if (localStorage.getItem('didBuyBellicon') && !coupon && !trial) {
        localStorage.setItem('belliconplusCupon','THANKYOU30')
        localStorage.setItem('belliconplusCuponType', 'yearly')
        return
      }
      
      // Check for extendedTrial parameter
      if (trial && trial === '22d376b4-ded5-4ed5-b82b-3b25fd2132eb' ) {
        localStorage.setItem('didBuyBellicon', JSON.stringify(true))
        localStorage.setItem('extendedTrial', '45')
        localStorage.removeItem('belliconplusCupon')
        localStorage.removeItem('belliconplusCuponType')
      }

      if (coupon) {
        // Define coupon mappings
        const couponMappings: {[key: string]: { code?: string, type?: 'yearly' | 'monthly' | 'both', trial?: string }} = {
          // UUID to coupon code mappings
          'a36a52c0-3069-4957-a037-85e366d0e5b9': { code: 'THANKYOU30', type: 'yearly', trial: '30' },
          'b10c9780-14ad-414f-b40a-4594c6b5d99b': { code: 'STARTNOW30', type: 'yearly', trial: '30' },
          'f619049c-127d-4cea-8662-dab81a532ac0': { code: 'GETSTARTED30', type: 'yearly', trial: '30' },
          'e600b952-d44f-4b12-ad4d-724972ae2029': { code: 'STARTBELLICONPLUS30', type: 'yearly', trial: '30' },
          // Special case: 45-day trial without specific coupon
          '22d376b4-ded5-4ed5-b82b-3b25fd2132eb': { trial: '45' },
          // Add more mappings as needed
        }

        // Handle UUID style coupons
        if (couponMappings[coupon as string]) {
          const mappedCoupon = couponMappings[coupon as string]
          localStorage.setItem('didBuyBellicon', JSON.stringify(true))

          // Set coupon code and type if available
          if (mappedCoupon.code && mappedCoupon.type) {
            localStorage.setItem('belliconplusCupon', mappedCoupon.code)
            localStorage.setItem('belliconplusCuponType', mappedCoupon.type)
          } else if (mappedCoupon.trial) {
            // For trial-only UUIDs, set a generic coupon code based on trial days
            localStorage.setItem('belliconplusCupon', `TRIAL${mappedCoupon.trial}`)
            localStorage.setItem('belliconplusCuponType', 'both')
          }

          // Also set extendedTrial if available in the mapping
          if (mappedCoupon.trial) {
            localStorage.setItem('extendedTrial', mappedCoupon.trial)
          }
        }
        // Handle direct coupon codes
        else {
          // Decode URL encoded coupon if necessary
          const decodedCoupon = decodeURIComponent(coupon as string)

          // Parse coupon info
          // Example format: COUPONCODE:type (e.g., "SUMMER30:yearly" or "SALE20:both")
          let couponCode = decodedCoupon
          let couponType: 'yearly' | 'monthly' | 'both' = 'both' // Default to both
          let trialDays: string | null = null

          if (decodedCoupon.includes(':')) {
            const parts = decodedCoupon.split(':')
            couponCode = parts[0]

            // Check if we have type information
            if (parts.length > 1 && ['yearly', 'monthly', 'both'].includes(parts[1])) {
              couponType = parts[1] as 'yearly' | 'monthly' | 'both'
            }

            // Check if we have trial days information
            if (parts.length > 2 && /^\d+$/.test(parts[2])) {
              trialDays = parts[2]
            }
          } else {
            // Try to extract trial days from the coupon code itself if it ends with a number
            const match = couponCode.match(/(\d+)$/);
            if (match) {
              trialDays = match[1];
            }
          }

          // Store the coupon information
          localStorage.setItem('didBuyBellicon', JSON.stringify(true))
          localStorage.setItem('belliconplusCupon', couponCode)
          localStorage.setItem('belliconplusCuponType', couponType)

          // Set extended trial if we extracted it
          if (trialDays) {
            localStorage.setItem('extendedTrial', trialDays)
          }
        }
      }
    },
    onSupport() {
      zendeskOpen()
    },
    experifyClick() {
      if (Experify) {
        Experify.openExperifyPlugin()
      }
    },
  },
})
